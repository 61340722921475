import styled from "styled-components"

export const BooksList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

export const BookItem = styled.li`
  border-right: 1px solid #707070;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    border-right: 1px solid transparent;
  }
`

export const BookFigure = styled.figure`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0;
  }
`
export const Caption = styled.figcaption`
  color: #f2eeed;
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.43px;
  line-height: 1.9;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: auto;
  margin-bottom: 1.4em;
`
export const Prev = styled.button`
  position: absolute;
  top: 25%;
  left: 0;
  z-index: 2;
`
export const Next = styled.button`
  position: absolute;
  top: 25%;
  right: 0;
  z-index: 2;
`

import SvgRibbon from "components/Ribbon/SvgRibbon"
import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { BookFigure, BookItem, BooksList, Caption, Prev, Next } from "./styles"
import { isMobile } from "utils"
import "swiper/css"
import { getLangFromPath } from "../../utils/hooks"

const Books = ({ books, english }) => {
  if (!books) return null

  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => {
        setCurrentIndex(swiper.realIndex)
      })
    }
  }, [swiper])

  const handleNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const handlePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  return (
    <>
      {isMobile ? (
        <Swiper
          parallax={true}
          slidesPerView={1.5}
          centeredSlides={true}
          onSwiper={setSwiper}
          spaceBetween={20}
        >
          {books?.map((book, index) => {
            return (
              <SwiperSlide key={index}>
                <BookItem
                  key={index}
                  style={{ opacity: currentIndex === index ? 1 : 0.4 }}
                >
                  <BookFigure>
                    <img src={book.cover.url} alt={book.cover.title} />
                    <Caption>{book.title}</Caption>
                  </BookFigure>
                  <SvgRibbon
                    popup={book.popup}
                    title={
                      getLangFromPath() === "en"
                        ? "See details"
                        : "Szczegóły dzieła"
                    }
                  ></SvgRibbon>
                </BookItem>
              </SwiperSlide>
            )
          })}
          {books.length && (
            <>
              <Prev
                onClick={handlePrev}
                style={{ display: currentIndex === 0 ? "none" : "block" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                >
                  <g>
                    <g transform="rotate(-180 5 9)">
                      <path
                        fill="none"
                        stroke="#dad2d2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="20"
                        stroke-width="2"
                        d="M1.4 1.8v0L8.6 9v0l-7.2 7.2v0"
                      />
                    </g>
                  </g>
                </svg>
              </Prev>
              <Next
                onClick={handleNext}
                style={{
                  display: currentIndex === books.length - 1 ? "none" : "block",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                >
                  <g>
                    <g>
                      <path
                        fill="none"
                        stroke="#dad2d2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="20"
                        stroke-width="2"
                        d="M1.4 1.8v0L8.6 9v0l-7.2 7.2v0"
                      />
                    </g>
                  </g>
                </svg>
              </Next>
            </>
          )}
        </Swiper>
      ) : (
        <BooksList>
          {books?.map((book, index) => {
            return (
              <BookItem key={index}>
                <BookFigure>
                  <img src={book.cover.url} alt={book.cover.title} />
                  <Caption>{book.title}</Caption>
                </BookFigure>
                <SvgRibbon
                  popup={book.popup}
                  title={
                    getLangFromPath() === "en"
                      ? "See details"
                      : "Szczegóły dzieła"
                  }
                ></SvgRibbon>
              </BookItem>
            )
          })}
        </BooksList>
      )}
    </>
  )
}

export default Books
